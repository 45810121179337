import type { HomepageContentProps } from '../../utilities/homepage.type'
import { CallToActionCard } from '@abcam-web/browse/ui'
import { LaunchSearchOverlayButton } from '@abcam-web/search/search-overlay'
import type { LinkButtonAssetModel } from '@abcam-web/shared/ui/content'
import { AEMImage, AEMRenditions } from '@abcam-web/shared/ui/content'
import { Button } from '@lego/ui/button'
import { useBreakpointListener } from '@lego/hooks/breakpoint-listener'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useCountry } from '@abcam-web/shared/utilities/country'
import styles from './homepage-content.module.css'

const renderCards = (
  items: {
    imageUrl: string | null
    imageCaption: string | null
    title: string
    text: string
    relativeUrl: string
    buttonLink: LinkButtonAssetModel | null
  }[]
) => {
  return items?.map(({ imageUrl, imageCaption, title, text, buttonLink }) => (
    <CallToActionCard
      key={title}
      imageSrc={imageUrl}
      title={title}
      text={text}
      buttonLink={buttonLink}
      imageCaption={imageCaption}
    />
  ))
}

const HomepageContent = ({
  heroImageUrl,
  heroImageCaption,
  strapline,
  products,
  features,
  uspHeader,
  usp,
  uspLarge,
}: HomepageContentProps) => {
  const isLargeLayout = useBreakpointListener(800, '>')
  const { country } = useCountry()
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col text-[#071112]">
      {/* header */}
      <div
        className="flex px-8 py-3 bg-main bg-grey-30 min-h-[496px]"
        style={
          heroImageUrl
            ? {
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.6)), url(${heroImageUrl})`,
              }
            : {}
        }
      >
        <div className="w-full m-auto">
          {strapline && (
            <div className="mb-8 font-bold text-center text-white text-heading-xxlarge font-header mdd:hidden">
              {strapline}
            </div>
          )}
          <LaunchSearchOverlayButton />
        </div>
      </div>
      {/* products */}
      {products && (
        <div className="mx-auto mt-[72px] xl:max-w-[1120px] xl:px-0 px-[30px]">
          <h2 className="mb-8 text-heading-xlarge font-header">
            {formatMessage({ id: 'homepage.browseByProducts' })}
          </h2>
          <div className="space-y-11 md:flex md:space-x-11 md:space-y-0">
            {renderCards(products)}
          </div>
        </div>
      )}
      {/* featured content */}
      {features && (
        <div className="mx-auto my-[72px] xl:max-w-[1120px] xl:px-0 px-[30px]">
          <h2 className="mb-8 text-heading-xlarge font-header">
            {formatMessage({ id: 'homepage.featuredContent' })}
          </h2>
          <div className="space-y-11 md:flex md:space-x-11 md:space-y-0">
            {renderCards(features)}
          </div>
        </div>
      )}
      {usp && (
        <div
          className={clsx(
            'bg-black-0 pb-[88px] pt-[66px]',
            country.toLocaleLowerCase() === 'jp' ||
              country.toLocaleLowerCase() === 'cn'
              ? styles[`uspVisibility-${country.toLocaleLowerCase()}`]
              : styles.uspVisibility
          )}
        >
          <div className="mx-auto xl:max-w-[1120px] xl:px-0 px-[30px]">
            {uspHeader && (
              <h2 className="mb-12 text-white text-heading-xlarge font-header">
                {uspHeader}
              </h2>
            )}
          </div>
          <div className="mx-auto space-y-11 md:flex md:space-x-11 md:space-y-0 xl:max-w-[1120px] xl:px-0 px-[30px]">
            {usp.map(
              ({
                imageType,
                imageCaption,
                imageUrl,
                title,
                text,
                callToAction,
              }) => (
                <div key={title} className="text-white">
                  {imageType === 'Icon' ? (
                    <img
                      alt={imageCaption ?? title}
                      src={imageUrl}
                      className="mb-2 h-[60px] w-[60px]"
                    />
                  ) : (
                    <img
                      alt={imageCaption ?? title}
                      src={imageUrl}
                      className="mb-2"
                    />
                  )}
                  <p className="mb-2 text-heading-x-small font-body-semibold">
                    {title}
                  </p>
                  <p className="text-body-xmedium font-body">{text}</p>

                  {callToAction ? (
                    <Button
                      as="a"
                      href={callToAction.buttonLink}
                      size={isLargeLayout ? 'small' : 'medium'}
                      variant="secondary"
                      className="mt-8 border border-white md:mt-6"
                    >
                      {callToAction.buttonLabel}
                    </Button>
                  ) : null}
                </div>
              )
            )}
          </div>
        </div>
      )}
      {uspLarge && (
        <div
          className={clsx(
            'w-full mx-auto py-[72px] xl:px-0 px-[30px] sm:px-[61px]',
            country.toLocaleLowerCase() === 'jp' ||
              country.toLocaleLowerCase() === 'cn'
              ? styles[`uspLargeVisibility-${country.toLocaleLowerCase()}`]
              : styles.uspLargeVisibility
          )}
          style={{
            backgroundImage:
              'linear-gradient(179deg, #4ba6b3 4%, #c9d3b7 37%, #ff8730 70%, #c54428 98%)',
          }}
        >
          <div className="mx-auto md:flex md:space-x-[46px] bg-grey-5 max-w-[1120px]">
            <div className="self-center px-10 py-8 mdu:py-10 lgu:py-16 md:pl-[62px] basis-1/2">
              <p className="mb-6 font-bold text-heading-large font-header">
                {uspLarge.title}
              </p>
              <p className="text-body-large font-body">{uspLarge.text}</p>

              {uspLarge.callToAction ? (
                <Button
                  as="a"
                  href={uspLarge.callToAction.buttonLink}
                  size={isLargeLayout ? 'small' : 'medium'}
                  className="mt-5 md:mt-6"
                >
                  {uspLarge.callToAction.buttonLabel}
                </Button>
              ) : null}
            </div>
            <div className="min-h-full basis-1/2">
              <AEMImage
                forceRendition={AEMRenditions.sm}
                alt={uspLarge.imageCaption ?? uspLarge.title}
                src={uspLarge.imageUrl}
                fullHeight
                pictureClassName="h-full w-full flex"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export { HomepageContent }
