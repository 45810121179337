import { MagnifierIcon } from '@lego/ui/icons'
import { tags } from '@tagging-shared/browse'
import { useSearch } from '@abcam-web/search/utilities'
import {
  useGtm,
  getSearchOverlayContext,
  LocationID,
  GTMPageType,
} from '@abcam-web/shared/data-access/tracking'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { useCountry } from '@abcam-web/shared/utilities/country'

export const LaunchSearchOverlayButton = () => {
  const { send } = useSearch()
  const { asPath } = useRouter()
  const gtm = useGtm()
  const { formatMessage } = useIntl()
  const { countryName } = useCountry()

  const placeholder = formatMessage({ id: 'common.homePlaceholder' })

  return (
    <button
      className={`
        appearance-none bg-white rounded-full
        flex flex-grow items-center
        border border-white border-solid
        h-20 w-full max-w-screen-smu mx-auto
        tyd:text-heading-xx-small
        smd:text-heading-small text-heading-medium cursor-text text-black-0
      `}
      data-cy={tags.homePage.launchSearchButton}
      data-testid="launch-search-overlay"
      onClick={() => {
        send('ACTIVATE')
        gtm.track(
          getSearchOverlayContext({
            click_id: 'search overlay',
            location_id: LocationID.PAGE,
            page_type: GTMPageType.HOMEPAGE,
            page_subtype: '',
            page_path: asPath,
            item_cta: 'What can we help you find today?',
            country: countryName,
          })
        )
      }}
    >
      <MagnifierIcon
        className={`
          w-6 min-w-6
          ml-7 tyd:mr-2 mr-6
          cursor-pointer
          fill-black
        `}
        data-testid="search-input-magnifier"
      />
      <span
        className={`
          overflow-hidden
          whitespace-nowrap
          overflow-ellipsis
        `}
      >
        {placeholder}
      </span>
    </button>
  )
}
