import { OrganizationJsonLd } from 'next-seo'

export const OrganizationSchema = () => {
  return (
    <OrganizationJsonLd
      additionalType="http://www.productontology.org/doc/Category:Biotechnology_companies"
      address={{
        streetAddress: 'Discovery Drive, Cambridge Biomedical Campus',
        addressLocality: 'Cambridge',
        addressRegion: 'England',
        addressCountry: 'UK',
        postalCode: 'CB2 0AX',
        postcode: 'CB2 0AX',
      }}
      areaServed="Worldwide"
      description="As an innovator in reagents and tools, abcam provides the research and clinical communities with tools and scientific support. We offer highly validated biological binders and assays to address important targets in critical biological pathways. We are already a pioneer in data sharing and ecommerce in the life sciences, but our ambition is to be the most influential company in life sciences by helping advance global understanding of biology and causes of disease, which, in turn, will drive new treatments and improved health."
      employee={{
        '@type': 'Person',
        name: 'Markus Lusser',
        jobTitle: 'President',
      }}
      faxNumber="+44-122-321-5215"
      founder={{
        '@type': 'Person',
        name: 'Jonathan Milner',
        honorificPrefix: 'Dr',
      }}
      foundingDate="1998-10"
      foundingLocation={{
        '@type': 'Place',
        name: 'Cambridge',
        containedInPlace: ['Cambridgeshire', 'England', 'United Kingdom'],
      }}
      legalName="Abcam Limited"
      logo="https://a.static-abcam.com/images/layouts/logo-r27.0.png"
      name="Abcam"
      sameAs={[
        'https://www.facebook.com/abcam',
        'https://twitter.com/abcam',
        'https://www.linkedin.com/company/abcam',
        'https://en.wikipedia.org/wiki/Abcam',
        'https://www.abcam.cn/',
        'https://www.abcam.co.jp/',
      ]}
      parentOrganization={{
        '@type': 'Organization',
        name: 'Danaher Corporation',
        url: 'https://www.danaher.com/',
      }}
      subOrganization={[
        {
          '@type': 'Organization',
          name: 'Epitomics, Inc',
          url: 'http://www.epitomics.com/',
        },
        {
          '@type': 'Organization',
          name: 'MitoSciences Inc',
        },
        {
          '@type': 'Organization',
          name: 'AxioMx',
        },
        {
          '@type': 'Organization',
          name: 'Firefly BioWorks',
          url: 'http://https://www.fireflybio.com/',
        },
        {
          '@type': 'Organization',
          name: 'Calico Biolabs',
        },
        {
          '@type': 'Organization',
          name: 'Marker Gene Technologies Inc',
        },
        {
          '@type': 'Organization',
          name: 'Applied StemCell Inc',
        },
        {
          '@type': 'Organization',
          name: 'BioVision Inc',
          url: 'https://www.biovision.com/',
        },
      ]}
      telephone="+44-122-369-6000"
      type="Organization"
      url="https://www.abcam.com/"
    />
  )
}
