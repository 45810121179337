import { createAemContentService } from '@abcam-web/browse/aem'
import {
  buildHreflangs,
  buildCanonicalUrl,
  findCorrespondingPWSPageForRequest,
  PageSeo,
} from '@abcam-web/shared/utilities/seo'
import { OrganizationSchema } from './utilities/organization-schema'
import { transformData } from './utilities/homepage.transform'
import { HomepageContent } from './ui/homepage-content/homepage-content'
import type { GetServerSideProps, GetServerSidePropsResult } from 'next'
import type {
  HomepageProps,
  HomepageResponse,
  HomepageTransformedData,
} from './utilities/homepage.type'

const Homepage = ({
  seo,
  content,
  canonicalUrl,
  hreflangs,
  locale,
}: HomepageProps) => {
  const noData = !seo || !content

  return (
    <>
      <PageSeo
        {...{
          hreflangs,
          canonicalUrl,
        }}
      />
      <OrganizationSchema />
      {/* Waiting for feedback on what to do if there's no data */}
      {!noData ? <HomepageContent {...content} /> : null}
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  resolvedUrl,
  locale,
  locales,
  defaultLocale,
  req,
}): Promise<GetServerSidePropsResult<HomepageProps>> => {
  const aemContentService = await createAemContentService(locale)

  const transformedData = await aemContentService.getContentFor<
    HomepageResponse,
    HomepageTransformedData | null
  >('GetHomepage', transformData)

  return {
    props: {
      ...(locale && {
        canonicalUrl: buildCanonicalUrl(locale, resolvedUrl),
      }),
      ...(locales &&
        defaultLocale && {
          hreflangs: buildHreflangs((locale) =>
            buildCanonicalUrl(locale, resolvedUrl)
          )(findCorrespondingPWSPageForRequest(req)),
        }),
      locale,
      ...transformedData,
    },
  }
}

export default Homepage
